
import config from './config';
import * as Content from '@adsk/content-sdk';

const prepareContent = content => {
  const result = {};
  const previewBoxSize = 100;
  for (const preview of content.components.thumbnails) {
    result.preview = { url: preview.fileUrl, width: previewBoxSize, height: previewBoxSize };
    break;
  }

  result.contentId = content.id;
  result.name = content.components.extension.data.displayName;
  result.description = content.components.info.description;
  result.hostItem = { contentId: content.id, files: [] };
  for (const fileInfo of content.components.files) {
    const primary = fileInfo.extension.toUpperCase().endsWith(config.primaryFile.toUpperCase());
    result.hostItem.files.push(
      {
        title: fileInfo.name,
        url: fileInfo.fileUrl,
        fileSize: fileInfo.size,
        primary,
      });
  }

  return result;
};

const fcsManager = {

  async initializeSDK() {

    const options = {
      httpOptions: {
        baseURL: config.fcsEndPoint,
        oAuth2Func: config.tokenGenerator.getToken,
      },
    };

    fcsManager.contentSearch = new Content.ForgeContentClient(options);

  },

  async isInternetConnected() {
    const http = new XMLHttpRequest();
    http.timeout = config.timeout;
    http.open('HEAD', window.location.href, true);
    http.setRequestHeader('cache-control', 'no-cache');
    http.send(null);
    return new Promise((resolve, reject) => {
      http.onreadystatechange = () => {
        if (http.readyState === 4) {
          if (http.status === 200) {
            resolve(true);
          }
          else {
            reject(false);
          }
        }
      };
    });
  },

  async accessToInternet() {
    try {
      await fcsManager.isInternetConnected();
      return true;
    } catch (err) {
      return false;
    }
  },

  async searchContentFromLibrary(searchQuery, page, filter) {

    if (searchQuery) {
      const specialSymbols = ['-', '(', ')', '~', '^', '!', '{', '}', '[', ']'];
      for (const symbol of specialSymbols) {
        const regExp = new RegExp(`\\${symbol}`, 'g');
        searchQuery = searchQuery.replace(regExp, '\\' + symbol);
      }
    }
    const request = {
      libraryId: config.libraryId,
      collectionId: config.collectionId,
      query: searchQuery,
      filter,
      facets: ['category'],
      count: true,
      orderBy: ['components.extension.data.displayName:asc'],
      offset: page * config.itemsPerPage,
      limit: config.itemsPerPage,
    };

    const startTime = performance.now();
    const response = await fcsManager.contentSearch.searchContentItems(request);
    const searchTime = performance.now() - startTime; // in milliseconds
    if (window.HostApp && window.HostApp.collectADPData && window.HostApp.collectADPDataEx) {
      if (config.startupTime > 0) {
        window.HostApp.collectADPData('FCS.Startup.Time', (Date.now() - config.startupTime).toString());
        config.startupTime = -1;
      }
      const searchParams = {};
      for (const key of Object.keys(filter)) {
        searchParams['FCS.Search.' + key] = filter[key];
      }
      searchParams['FCS.Search.FromCache'] = false;
      if (searchTime < 217) {
        searchParams['FCS.Search.FromCache'] = true;
      }
      searchParams['FCS.Search.Time'] = Math.round(searchTime).toString();
      searchParams['FCS.Search.Query'] = searchQuery;
      searchParams['FCS.Search.Count'] = response.data.pagination.totalResults;
      window.HostApp.collectADPDataEx(JSON.stringify(searchParams));
    }

    const items = [];
    for (const item of response.data.results) {
      items.push(prepareContent(item));
    }

    let queryCategories = [];
    let browsingInfo = [];
    const facetCategories = response.data.facets.category;
    queryCategories = facetCategories.buckets.map(facetItem => facetItem.key);
    const facetHierarchies = response.data.facets.hierarchy;
    browsingInfo = facetHierarchies.buckets.map(facetItem => facetItem.key);

    const paginationData = response.data.pagination;
    const resTotalPages = Math.ceil(paginationData.totalResults / paginationData.limit);
    const result = {
      items,
      queryCategories,
      browsingInfo,
      totalCount: paginationData.totalResults,
      totalPages: resTotalPages,
    };

    return result;
  },

};

export default fcsManager;
