
export const errorStatusChanged = errorStatus => ({
  type: 'ERROR_STATUS_CHANGED',
  errorStatus,
});

export const filterChanged = filter => ({
  type: 'FILTER_CHANGED',
  filter,
});

export const searchQueryChanged = searchQuery => ({
  type: 'SEARCH_QUERY_CHANGED',
  searchQuery,
});

export const updateSearchInput = searchQuery => ({
  type: 'UPDATE_SEARCH_INPUT',
  searchQuery,
});

export const itemsInfoChanged = itemsInfo => ({
  type: 'ITEMS_INFO_CHANGED',
  itemsInfo,
});

export const itemsInfoPageChanged = itemsInfo => ({
  type: 'ITEMS_INFO_PAGE_CHANGED',
  itemsInfo,
});

export const currentPageChanged = currentPage => ({
  type: 'CURRENT_PAGE_CHANGED',
  currentPage,
});

export const itemSelectedChanged = selectedItem => ({
  type: 'ITEM_SELECTED_CHANGED',
  selectedItem,
});

export const itemsCheckedChanged = checkedItems => ({
  type: 'ITEMS_CHECKED_CHANGED',
  checkedItems,
});

export const lastSelectedIndexChanged = lastSelectedIndex => ({
  type: 'LAST_SELECTED_INDEX_CHANGED',
  lastSelectedIndex,
});

export const browsingItemsInfoChanged = browsingItemsInfo => ({
  type: 'BROWSING_ITEMS_INFO_CHANGED',
  browsingItemsInfo,
});

export const metaChanged = metaData => ({
  type: 'META_CHANGED',
  metaData,
});

export const homeReset = () => ({
  type: 'HOME_RESET',
});

export const facetedNavChanged = facetedNav => ({
  type: 'FNAV_CHANGED',
  facetedNav,
});

export const historyChanged = history => ({
  type: 'HISTORY_CHANGED',
  history,
});

export const pastFutureHistoryChanged = history => ({
  type: 'PAST_FUTURE_HISTORY_CHANGED',
  history,
});

export const nothingChanged = () => ({
  type: 'NOTHING_CHANGED',
});

export const refreshSearch = () => ({
  type: 'REFRESH_SEARCH',
});

export const toggleContentDisplayType = () => ({
  type: 'DISPLAY_TYPE_TOGGLED',
});

export const openAboutBox = shouldOpenAboutBox => ({
  type: 'OPEN_ABOUT_BOX',
  shouldOpenAboutBox,
});

export const openProgressDialog = shouldShowProgress => ({
  type: 'OPEN_PROGRESS_DIALOG',
  shouldShowProgress,
});

export const showBottleChanged = showBottle => ({
  type: 'SHOW_BOTTLE_CHANGED',
  showBottle,
});

export const FreezeMode = {
  UNFROZEN: 1,
  UPLOAD_PROGRESS: 2,
  SEARCH_PROGRESS: 3,
};

export const freezeApp = (mode, loading) => ({
  type: 'FREEZE_APP',
  mode,
  loading,
});

export const actionsToProps = actions => dispatch => {
  // actions = { freezeApp: freezeApp, ... }
  // result = { freezeApp: (...args) => dispatch(freezeApp(...args)), ... }
  const result = {};
  Object.entries(actions).forEach(([name, action]) => {
    result[name] = (...args) => dispatch(action(...args));
  });
  return result;
};
