const logToJounral = msg => {
  if (window.HostApp && window.HostApp.logJournalComment) {
    window.HostApp.logJournalComment('ContentDelivery: ' + msg);
  }
};

const logSuccess = () => {
  // no success logging in production
};

const logError = (...args) => {
  console.error(...args);
  logToJounral('Error: ' + JSON.stringify({ ...args }));
};

const dbgTrace = () => {
  // no debug traces in production
};

let alwaysTrace = false;

const setAlwaysTrace = (doTrace = true) => {
  const oldVal = alwaysTrace;
  alwaysTrace = doTrace;
  return oldVal;
};

export { logToJounral, dbgTrace, setAlwaysTrace, logError, logSuccess };
