import React from 'react';
import PropTypes from 'prop-types';
import { T } from '../controller/locale';
import './SideBar.scss';

const SideBar = props => (
  <div className="sidebar-container">
    <h4 className="sidebar-filter-label">{T.translate('SB_LABEL_FILTER')}</h4>
    {props.children}
  </div>
);

SideBar.propTypes = {
  children: PropTypes.object,
};

export default SideBar;
