import React from 'react';
import PropTypes from 'prop-types';
import { T } from './controller/locale';
import Button, { widths } from '@hig/button';
import './RevitFooter.scss';
import makeSafeId from './view/makeSafeId';

const generateHelpLink = text => (
  <a href="#"
    onClick={() => {
      if (window.HostApp && window.HostApp.onHelp) {
        window.HostApp.onHelp();
      }
    }}>
    {text}
  </a>
);

const RevitFooter = props => {
  return (
    <div className="dialog-footer">
      <div className="dialog-footer__help-link">
        {generateHelpLink(T.translate('CDR_HELP_LINK_FAMILYLOAD'))}
      </div>
      <div className="dialog-footer-load-button">
        <Button
          type="primary"
          title={T.translate('CDR_LABEL_LOAD')}
          id={makeSafeId('btn-LOAD-FAMILY')}
          onClick={props.onHandleOnLoad}
          width={widths.GROW}
        />
      </div>
      <div className="dialog-footer-cancel-button">
        <Button
          type="secondary"
          title={T.translate('CDR_LABEL_CANCEL')}
          id={makeSafeId('btn-CANCEL')}
          onClick={props.onHandleOnCancel}
          width={widths.GROW}
        />
      </div>
    </div>
  );
};

RevitFooter.propTypes = {
  onHandleOnLoad: PropTypes.func,
  onHandleOnCancel: PropTypes.func,
};

export default RevitFooter;
