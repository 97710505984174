import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Table, { AutoResizer } from '@hig/table';
import '@hig/table/build/index.css';
import Tooltip from '@adsk/nirvana-tooltip';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProgressRing from '@hig/progress-ring';
import { prepareLangAttribute } from '../controller/locale';
import makeSafeId from './makeSafeId';
import Checkbox from '@hig/checkbox';

class ContentDisplayList extends Component {
  constructor(props) {
    super(props);
    this.displayResult = [];
  }

  static propTypes = {
    selectedItem: PropTypes.object,
    checkedItems: PropTypes.object,
    items: PropTypes.array,
    hasMoreItems: PropTypes.func,
    loadMoreItems: PropTypes.func,
    performSelection: PropTypes.func,
    doMove: PropTypes.func,
    filter: PropTypes.object,
    isContentLessThanWindowSize: PropTypes.func,
  };

  generateColumns(size) {
    const lang = prepareLangAttribute(this.props.filter);

    const columns = [
      {
        key: 'preview',
        dataKey: 'preview',
        width: size.width,
        title: '',
        renderCell: ({ cellData: item }) => (
          <div className={item.contentId === this.props.selectedItem.contentId ? 'display-content-item-list-selected' : 'display-content-item-list-container'}
            onClick={event => this.props.doMove(item.index, event.shiftKey, item)}
            id={'cont-disp-list-' + makeSafeId(item.description)}
            tabIndex={item.index === 0 ? 0 : -1}
            onKeyDown={event => {
              if (event.key.toLowerCase() === 'enter') {
                this.props.performSelection(item);
              }
              else if (event.which === 38 || event.which === 40) {
                let newIndex = item.index;
                if (event.which === 38) {
                  newIndex -= 1;
                } else if (event.which === 40) {
                  newIndex += 1;
                }
                if (newIndex >= 0 && newIndex < this.displayResult.length) {
                  document.getElementsByClassName('display-content-item-list-container')[newIndex].focus();
                  this.props.doMove(newIndex, event.shiftKey);
                }
              }
            }}>
            {(window.HostApp && window.HostApp.onLoadMulti) ?
              <div className={
                (this.props.checkedItems && Object.keys(this.props.checkedItems).length === 0) ? 'display-checkbox-hover' : 'display-checkbox-checked'
              }>
                <Checkbox
                  tabIndex={-1}
                  checked={this.props.checkedItems[item.contentId] !== undefined}
                />
              </div>
              : null}

            <img
              alt="Content Preview"
              className="display-content-preview-item"
              src={item.preview.url}
              width={item.preview.width / 2}
              height={item.preview.height / 2}
            />
            <Tooltip
              style={{ wordBreak: 'break-all' }}
              placement={Tooltip.Placements.BOTTOM}
              theme={Tooltip.Themes.LIGHT}
              content={item.description}>
              <div className="display-content-item-list-title" lang={lang}>{item.name}</div>
            </Tooltip>
          </div>
        ),
      },
    ];

    return columns;
  }

  isContentLessThanWindowSize() {
    const scrollDiv = document.getElementsByClassName('display-scrollable-div')[0];
    const contentTable = document.getElementsByClassName('display-content-table')[0];
    if (scrollDiv !== undefined && contentTable !== undefined) {
      return scrollDiv.clientHeight > contentTable.clientHeight;
    }
    return false;
  }

  async componentDidUpdate() {
    if (this.isContentLessThanWindowSize() && this.props.hasMoreItems()) {
      this.props.loadMoreItems();
    }
  }

  createDisplayItems() {
    this.displayResult = [];
    const displayItems = this.props.items;
    if (displayItems.length) {
      for (let i = 0; i < displayItems.length; i++) {
        const item = {
          ...displayItems[i],
          index: i,
        };
        const itemObj = {
          preview: item,
          name: item,
          actions: item.contentId,
          createTime: item.createTime,
          lastModifiedTime: item.lastModifiedTime,
          size: item.size,
        };
        this.displayResult.push(itemObj);
      }
    }
    return this.displayResult;
  }

  render() {
    return (
      <>
        <div id="scrollableDivList" className="display-scrollable-div">
          <InfiniteScroll
            dataLength={this.props.items.length}
            next={this.props.loadMoreItems}
            hasMore={this.props.hasMoreItems()}
            scrollableTarget="scrollableDivList"
            loader={<ProgressRing />}
          >
            {<div className="display-content-table"
              style={{ height: 60 * this.props.items.length }}>
              <AutoResizer>
                {size => (
                  <Table
                    tabIndex={-1}
                    hideHeader={true}
                    columns={this.generateColumns(size)}
                    data={this.createDisplayItems()}
                    width={size.width}
                    height={60 * this.props.items.length}
                    rowHeight={60}
                  />
                )}
              </AutoResizer>
            </div>
            }
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

const stateProps = state => ({
  items: state.itemsInfo.items,
  selectedItem: state.selectedItem,
  checkedItems: state.checkedItems,
  filter: state.filter,
});

export default connect(stateProps)(ContentDisplayList);
