import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ContentDelivery, { config } from './ContentDelivery';
import fcsManager from './controller/fcsManager';
import { logError } from './controller/logError';
import { setLanguage, prepareLangAttribute } from './controller/locale';
import RevitFooter from './RevitFooter';
import HIGLightGrayTheme from '@hig/theme-data/build/esm/lightGrayMediumDensityTheme';
import ThemeContext from '@hig/theme-context';
//import Downloader from './view/Downloader';

const onPersist = stateStr => {
  if (window.HostApp && window.HostApp.setAppStorage) {
    window.HostApp.setAppStorage(stateStr);
  }
};

class ContentDeliveryRevit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: {},
      language: 'en',
      product: 'Revit',
      oldAppStorage: '',
      hostDataReady: false,
      itemsToLoad: null,
    };
    this._handleOnLoad = this._handleOnLoad.bind(this);
  }

  async componentDidMount() {
    // Setup all data that comes locally, from the server
    await this.setupEnv();
    await Promise.all([
      this.setupSDK(),
      this.setupLanguage(),
      this.setupOldAppStorage(),
      this.setupProduct(),
      this.setupCategories(),
      this.setupStartupTime(),
    ]);
    this.setState({ hostDataReady: true });
  }

  async setupSDK() {
    if (window.HostApp && window.HostApp.getOAuth2Token) {
      config.tokenGenerator.getToken = window.HostApp.getOAuth2Token;
    }
    await fcsManager.initializeSDK();
  }

  async setupStartupTime() {
    config.startupTime = -1;
    if (window.HostApp && window.HostApp.getStartupTime) {
      config.startupTime = await window.HostApp.getStartupTime();
    }
  }

  async setupProduct() {
    if (window.HostApp && window.HostApp.getProductType) {
      this.setState({ product: await window.HostApp.getProductType() });
    }
  }

  async setupCategories() {
    if (window.HostApp && window.HostApp.getCategories) {
      this.setState({
        categories: JSON.parse(await window.HostApp.getCategories()),
      });
    }
  }

  async setupEnv() {
    if (window.HostApp && window.HostApp.getVersionBuild) {
      // For Revit 2021.1, returns something like 21.1.21.45 == <Major>.<Minor>.<Revision>.<BuildNum>
      const version = await window.HostApp.getVersionBuild();
      if (version) {
        // Convert to Number
        config.revitVersion = parseInt(version);
      }
    }
    if (window.HostApp && window.HostApp.shouldDisplayNotification)
    {
      config.revitVersion = 21;
    }
    // Note that these setFCSXxx() calls rely on `revitVersion` being set
    config.setFCSStg(config); //staging by default
    if (window.HostApp && window.HostApp.isUsingStagingServer) {
      const stagingEnv = await window.HostApp.isUsingStagingServer();
      if (!stagingEnv) {
        config.setFCSPrd(config);
      }
    }
    // Intended for a regression harness to inject a custom Lib Id, not so much for production:
    if (window.HostApp && window.HostApp.getCustomLibraryId) {
      const custLibraryId = await window.HostApp.getCustomLibraryId();
      if (custLibraryId) {
        config.libraryId = custLibraryId;
      }
    }
  }

  async setupLanguage() {
    if (window.HostApp && window.HostApp.getLanguage) {
      const language = await window.HostApp.getLanguage();
      setLanguage(language);
      this.setState({ language });
    }
  }

  async setupOldAppStorage() {
    if (window.HostApp && window.HostApp.getAppStorage) {
      const oldAppStorage = await window.HostApp.getAppStorage();
      this.setState({ oldAppStorage });
    }
  }

  _handleOnLoad() {
    if (window.HostApp && window.HostApp.setAppStorage) {
      window.HostApp.setAppStorage(this.child.serialize());
    }

    if (window.HostApp && window.HostApp.onLoadMulti) {
      const checkedItems = this.child.getCheckedItems();
      if (checkedItems && Object.keys(checkedItems).length !== 0) {
        this.setState({ itemsToLoad: checkedItems });
        this.child.startDownload();
      }
      return;
    }

    if (window.HostApp && window.HostApp.onLoad) {
      const selectedItem = this.child.getSelectedItem();
      if (selectedItem && selectedItem.hostItem) {
        try {
          const description = selectedItem.description.replace(/\\/g, '/');
          const hostItemData = JSON.stringify({ ...selectedItem.hostItem, description });
          window.HostApp.onLoad(hostItemData);
        } catch (err) {// When and how can this fail? Should we tell the user then?
          logError(err);
          if (this.child) {
            this.child.postError();
          }
        }
      }
    }
  }

  _handleOnLoadMulti() {
    if (window.HostApp && window.HostApp.onLoadMulti) {
      window.HostApp.onLoadMulti();
    }
  }

  _handleOnCancel() {
    if (window.HostApp && window.HostApp.onCancel) {
      window.HostApp.onCancel();
    }
  }

  render() {
    if (!this.state.hostDataReady) {
      return null;
    }
    return (
      <ThemeContext.Provider value={HIGLightGrayTheme}>
        <div className="revit-app-container" lang={prepareLangAttribute({ language: this.state.language })}>
          <ContentDelivery
            onRef={ref => (this.child = ref)}
            language={this.state.language}
            product={this.state.product}
            serializedData={this.state.oldAppStorage}
            onPersist={onPersist}
            filters={{
              values: { 'category': { enum: [] } },
              translations: {
                'category': {
                  label: 'F_CATEGORY',
                  enum: this.state.categories,
                },
              },
            }}
            onHandleOnLoadMulti={this._handleOnLoadMulti} //disadvantage of not having the store available here
            itemsToLoad={this.state.itemsToLoad}
          />
          <RevitFooter onHandleOnLoad={this._handleOnLoad} onHandleOnCancel={this._handleOnCancel} />
        </div>
      </ThemeContext.Provider>
    );
  }
}

ContentDeliveryRevit.propTypes = {
  language: PropTypes.string,
};

export default ContentDeliveryRevit;
