import { combineReducers } from 'redux';
import { ContentDisplayType } from '../view/ContentDisplay';

const product = (state = {}) => {
  return state;
};

const filter = (state = {}, action) => {
  switch (action.type) {
    case 'FILTER_CHANGED':
      return action.filter;
    default:
      return state;
  }
};

const metaData = (state = {}, action) => {
  switch (action.type) {
    case 'META_CHANGED':
      return action.metaData;
    case 'PAST_FUTURE_HISTORY_CHANGED':
      return action.history.present.metaData;
    case 'HOME_RESET':
    case 'FILTER_CHANGED':
      return {};
    default:
      return state;
  }
};

const selectedItem = (state = {}, action) => {
  switch (action.type) {
    case 'ITEM_SELECTED_CHANGED':
      return action.selectedItem;
    case 'ITEMS_INFO_CHANGED':
      return {};
    default:
      return state;
  }
};

// 0 as default so the first item is "selected" for a new search.
const lastSelectedIndex = (state = 0, action) => {
  switch (action.type) {
    case 'LAST_SELECTED_INDEX_CHANGED':
      return action.lastSelectedIndex;
    case 'ITEMS_INFO_CHANGED':
      return 0; // reset to first item
    default:
      return state;
  }
};

const checkedItems = (state = {}, action) => {
  switch (action.type) {
    case 'ITEMS_CHECKED_CHANGED':
      return action.checkedItems;
    case 'ITEMS_INFO_CHANGED':
      return {};
    default:
      return state;
  }
};

const facetedNav = (state = '', action) => {
  switch (action.type) {
    case 'FNAV_CHANGED':
      return action.facetedNav;
    case 'PAST_FUTURE_HISTORY_CHANGED':
      return action.history.present.facetedNav;
    case 'HOME_RESET':
    case 'FILTER_CHANGED':
      return '';
    default:
      return state;
  }
};

const browsingItemsInfo = (state = [], action) => {
  switch (action.type) {
    case 'BROWSING_ITEMS_INFO_CHANGED':
      return action.browsingItemsInfo;
    case 'SEARCH_QUERY_CHANGED':
    case 'FILTER_CHANGED':
    case 'META_CHANGED':
    case 'HOME_RESET':
    case 'FNAV_CHANGED':
    case 'PAST_FUTURE_HISTORY_CHANGED':
      return [];
    default:
      return state;
  }
};

const searchQuery = (state = '', action) => {
  switch (action.type) {
    case 'SEARCH_QUERY_CHANGED':
    case 'UPDATE_SEARCH_INPUT':
      return action.searchQuery;
    case 'PAST_FUTURE_HISTORY_CHANGED':
      return action.history.present.searchQuery;
    case 'HOME_RESET':
    case 'FILTER_CHANGED':
      return '';
    default:
      return state;
  }
};

const itemsInfo = (state = {}, action) => {
  switch (action.type) {
    case 'ITEMS_INFO_CHANGED':
    case 'ITEMS_INFO_PAGE_CHANGED':
      return action.itemsInfo;
    default:
      return state;
  }
};

const history = (state = {}, action) => {
  switch (action.type) {
    case 'HISTORY_CHANGED':
    case 'PAST_FUTURE_HISTORY_CHANGED':
      return action.history;
    default:
      return state;
  }
};

const currentPage = (state = 0, action) => {
  switch (action.type) {
    case 'CURRENT_PAGE_CHANGED':
      return action.currentPage;
    case 'SEARCH_QUERY_CHANGED':
    case 'FILTER_CHANGED':
    case 'META_CHANGED':
    case 'HOME_RESET':
    case 'FNAV_CHANGED':
    case 'PAST_FUTURE_HISTORY_CHANGED':
      return 0;
    default:
      return state;
  }
};

const contentDisplayType = (state = 0, action) => {
  switch (action.type) {
    case 'DISPLAY_TYPE_TOGGLED':
      if (state === ContentDisplayType.GRID) {
        return ContentDisplayType.LIST;
      } else {
        return ContentDisplayType.GRID;
      }
    default:
      return state;
  }
};

const shouldOpenAboutBox = (state = false, action) => {
  switch (action.type) {
    case 'OPEN_ABOUT_BOX':
      return action.shouldOpenAboutBox;
    default:
      return state;
  }
};

const shouldShowProgress = (state = false, action) => {
  switch (action.type) {
    case 'OPEN_PROGRESS_DIALOG':
      return action.shouldShowProgress;
    default:
      return state;
  }
};

const showBottle = (state = false, action) => {
  switch (action.type) {
    case 'SHOW_BOTTLE_CHANGED':
      return action.showBottle;
    default:
      return state;
  }
};

const loading = (state = {}, action) => {
  if (action.type === 'FREEZE_APP') {
    const { loading, mode } = action;
    if (loading) {
      loading.mode = mode;
      return loading;
    } else {
      return { mode };
    }
  } else {
    return state;
  }
};

const errorStatus = (state = {}, action) => {
  switch (action.type) {
    case 'ERROR_STATUS_CHANGED':
      return action.errorStatus;
    default:
      return state;
  }
};

export default combineReducers({
  product,
  filter,
  metaData,
  selectedItem,
  lastSelectedIndex,
  checkedItems,
  browsingItemsInfo,
  facetedNav,
  searchQuery,
  itemsInfo,
  history,
  currentPage,
  contentDisplayType,
  shouldOpenAboutBox,
  shouldShowProgress,
  showBottle,
  loading,
  errorStatus,
});
