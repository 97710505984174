const year_library_map = {
  21: {
    'stg': 'a592086e-9857-4197-8808-e9e3197028ac',
    'prod': '853c4c53-339b-4398-b1a9-2e859ea5738d',
  },
  22: {
    'stg': 'ee2cf9b2-29aa-4b8f-9af1-b72234fc8641',
    'prod': '7adad8e3-34e6-454d-b989-e60d24963a82',
  },
  23: {
    'stg': 'a25dcc4e-1b38-4ee9-8af3-904ad8d2ff48',
    'prod': 'efe21cfa-033f-42b0-ab1d-3783795f8710',
  },
  24: {
    'stg': '3f87a505-1fcf-4863-9c9c-c4811646be32',
    'prod': '890b0a5e-cbad-4d3d-ab0c-2e72f75fee70',
  },
  25: {
    'stg': 'b9f9bae3-e981-487c-9b83-cf3905217d3a',
    'prod': '561d30c7-fa15-4231-9908-6c7f364b069a',
  },
};

const config = {
  libraryId: '',
  fcsEndPoint: '',
  collectionId: '',
  primaryFile: 'rfa',
  timeout: 5000,
  tokenGenerator: { getToken: () => '' },
  startupTime: -1,
  revitVersion: 22,
  itemsPerPage: 100,

  // These need to be called _after_ `revitVersion` has been set!
  setFCSStg(cfg) {
    cfg.libraryId = year_library_map.hasOwnProperty(cfg.revitVersion) ? year_library_map[cfg.revitVersion]['stg'] : year_library_map[25]['stg'];
    cfg.fcsEndPoint = 'https://developer-stg.api.autodesk.com/content/v2';
    if (cfg.revitVersion < 24) {
      cfg.collectionId = cfg.libraryId;
    }
    else {
      cfg.collectionId = '68509828-8531-4174-84db-afdf0b0614ec';
    }
  },
  setFCSPrd(cfg) {
    cfg.libraryId = year_library_map.hasOwnProperty(cfg.revitVersion) ? year_library_map[cfg.revitVersion]['prod'] : year_library_map[25]['prod'];
    cfg.fcsEndPoint = 'https://developer.api.autodesk.com/content/v2';
    if (cfg.revitVersion < 24) {
      cfg.collectionId = cfg.libraryId;
    }
    else {
      cfg.collectionId = 'e685e6fd-1818-476f-90da-889c6f9f4f51';
    }
  },
};

export default config;
