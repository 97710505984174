import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { itemsCheckedChanged } from '../redux/actions';
import IconButton from '@hig/icon-button';
import { CloseSUI } from '@hig/icons';
import { T, prepareLangAttribute } from '../controller/locale';
import './Breadcrumbs.scss';

const isHistoryPresent = (props) => {
  const { searchQuery, facetedNav, metaData } = props.history.present;
  if (!searchQuery && !facetedNav && metaData && !Object.keys(metaData).length) {
    return false;
  }
  else {
    return true;
  }
};

class Breadcrumbs extends Component {
  constructor(props) {
    super(props);
    this.generateCrumbs = this.generateCrumbs.bind(this);
  }

  generateCrumbs() {
    const crumbsList = [];
    Object.entries(this.props.history.present).forEach(([key, value]) => {
      if (key === 'metaData' && Object.keys(value).length !== 0) {
        if (Object.keys(this.props.categoryTranslations.enum).length !== 0) {
          value = this.props.categoryTranslations.enum[value.category];
        }
        else {
          value = value.category; //for browser
        }
      }

      if (Object.keys(value).length !== 0) {
        crumbsList.push(value);
      }

    });
    return crumbsList;
  }

  render() {
    const lang = prepareLangAttribute(this.props.filter);
    return (
      <>
        {this.props.items.length ?
          <>
            <div className="display-items-count">
              {this.props.totalCount + T.translate('BC_RESULT_TEXT')}
            </div>
            {this.props.checkedItems && Object.keys(this.props.checkedItems).length > 0 ?
              <>
                <div className="display-items-count">
                  {'(' + Object.keys(this.props.checkedItems).length + T.translate('BC_SELECTED_TEXT')}
                </div>
                <div className="display-deselect-button">
                  <IconButton
                    title={T.translate('BC_CLEAR_SELECTION')}
                    icon={<CloseSUI />}
                    onClick={() =>
                      this.props.itemsCheckedChanged({})
                    }
                  />
                </div>
                <div className="display-items-count" style={{ marginLeft: '-10px' }}>{')'}</div>
              </>
              : null}
            {isHistoryPresent(this.props) ?
              <>
                <div className="display-items-count">{T.translate('BC_FOR_TEXT')}</div>
                <div className="display-items-breadcrumbs" lang={lang}>
                  <bdi>{this.generateCrumbs().join(' > ')}</bdi>
                </div>
              </>
              : null}
          </>
          : null}
      </>
    );
  }
}

Breadcrumbs.propTypes = {
  history: PropTypes.object,
  categoryTranslations: PropTypes.object,
  items: PropTypes.array,
  totalCount: PropTypes.number,
  filter: PropTypes.object,
  checkedItems: PropTypes.object,
  itemsCheckedChanged: PropTypes.func,
};

const mapStateToProps = state => ({
  history: state.history,
  items: state.itemsInfo.items,
  totalCount: state.itemsInfo.totalCount,
  filter: state.filter,
  checkedItems: state.checkedItems,
});

const mapDispatchToProps = dispatch => ({
  itemsCheckedChanged: checkedItems => dispatch(itemsCheckedChanged(checkedItems)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Breadcrumbs);
