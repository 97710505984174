import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ContentDisplay from './view/ContentDisplay';
import SearchContent from './view/SearchContent';
import SideBar from './view/SideBar';
import './ContentDelivery.scss';
import MetadataFilters from './view/MetadataFilters';
import FilterToggles from './view/FilterToggles';
import Banner from '@hig/banner';
import Button from '@hig/button';
import { actionsToProps, errorStatusChanged, refreshSearch } from './redux/actions';
import { T } from './controller/locale';
import HistoryNavigation from './view/HistoryNavigation';
import Breadcrumbs from './view/Breadcrumbs';
import AboutBox from './view/AboutBox';
import Downloader from './view/Downloader';
import NotificationBanner from './view/NotificationBanner';

const ContentDeliveryInner = props => {
  return (
    <>
      {props.errorStatus.show ?
        <Banner
          type="warning"
          onDismiss={() => props.errorStatusChanged({ show: false, text: '', goodConnection: true })}
          dismissButtonTitle={T.translate('NB_NOTIFICATION_CLOSE')}
          actions={() => (
            <Banner.Action>
              {!props.errorStatus.goodConnection ?
                <Button
                  type="secondary"
                  title={T.translate('CDI_ERROR_REFRESH_BUTTON')}
                  onClick={() => {
                    props.refreshSearch();
                  }}
                />
                : null}
            </Banner.Action>
          )}>
          {props.errorStatus.text}
        </Banner>
        : null}
      <NotificationBanner />
      <div className="display-component-service">
        <SideBar>
          <MetadataFilters filters={props.filters} />
        </SideBar>
        <div className="display-content-view">
          <div className="display-top-nav">
            <div className="display-search-area">
              <SearchContent />
              <FilterToggles />
            </div>
            <div className="display-navigation-controls">
              <HistoryNavigation />
              <Breadcrumbs categoryTranslations={props.filters.translations.category} />
            </div>
          </div>
          <ContentDisplay />
          <AboutBox />
          {props.shouldShowProgress ?
            <Downloader onHandleOnLoadMulti={props.onHandleOnLoadMulti}
              itemsToLoad={props.itemsToLoad} />
            : null}
        </div>
      </div>
    </>
  );
};


ContentDeliveryInner.propTypes = {
  filters: PropTypes.object,
  errorStatus: PropTypes.object,
  errorStatusChanged: PropTypes.func,
  refreshSearch: PropTypes.func,
  onHandleOnLoadMulti: PropTypes.func,
  shouldShowProgress: PropTypes.bool,
  itemsToLoad: PropTypes.object,
};

const stateProps = state => ({
  errorStatus: state.errorStatus,
  shouldShowProgress: state.shouldShowProgress,
});

const actionProps = actionsToProps({
  errorStatusChanged,
  refreshSearch,
});

export default connect(stateProps, actionProps)(ContentDeliveryInner);
