import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Navigator.scss';
import { prepareLangAttribute } from '../controller/locale';
import makeSafeId from './makeSafeId';

class Navigator extends Component {
  constructor(props) {
    super(props);
    this.generateNavigation = this.generateNavigation.bind(this);
    this.generateNavigationElement = this.generateNavigationElement.bind(this);
    this.idManager = {
      prefix: 'navigator-item-',
      acumulator: -1,
      getNext() {
        this.acumulator++;
        return this.prefix + this.acumulator;
      },
      getNextFromId(id, reverse) {
        let pos = parseInt(id.replace(this.prefix, ''));
        if (!reverse) {
          pos++;
        }
        else {
          pos--;
        }

        if (pos > this.acumulator) {
          pos = 0;
        }
        else if (pos < 0) {
          pos = this.acumulator;
        }

        return this.prefix + pos;
      },
    };
  }

  componentDidUpdate() {
    if (this.savedFocus) {
      const domElement = document.getElementsByClassName('navigator-item-selected');
      if (domElement && domElement.length) {
        domElement[0].focus();
        this.savedFocus = false;
      }
    }
  }

  generateNavigationElement(data) {

    const generateChildElements = () => {
      if (!data.children || !data.children.length) {
        return null;
      }

      const elements = data.children.map((child) => {
        return this.generateNavigationElement(child);
      });

      return (<ul className="navigator-ul">{elements}</ul>);
    };

    let styleName = 'navigator-item';
    if (data.id === this.props.currentSelection) {
      styleName = 'navigator-item-selected';
    }

    const id = this.idManager.getNext();
    const tabIndex = this.idManager.acumulator === 0 ? 0 : -1;

    const item = (<li key={data.id} id={id}>
      <div className={styleName}
        id={'navigator-' + makeSafeId(data.name)}
        tabIndex={tabIndex}
        onClick={() => {
          this.props.onSelectionChanged(data);
        }}
        onKeyDown={event => {
          if (event.key.toLowerCase() === 'enter') {
            this.props.onSelectionChanged(data);
            this.savedFocus = true;
          }
          else if (event.which === 38) {
            document.getElementById(this.idManager.getNextFromId(id, true)).firstChild.focus();
            event.preventDefault();
          }
          else if (event.which === 40) {
            document.getElementById(this.idManager.getNextFromId(id)).firstChild.focus();
            event.preventDefault();
          }
        }}>{data.name}</div>
      {generateChildElements()}
    </li>);

    return item;
  }

  generateNavigation() {
    this.idManager.acumulator = -1;
    const rootItems = this.props.navigationData.map((data) => {
      return this.generateNavigationElement(data);
    });

    const lang = prepareLangAttribute(this.props.filter);

    return (<ul className="navigator-ul-root" lang={lang}>{rootItems}</ul>);
  }

  render() {
    return this.generateNavigation();
  }
}

Navigator.propTypes = {
  filter: PropTypes.object,
  navigationData: PropTypes.array,
  onSelectionChanged: PropTypes.func,
  currentSelection: PropTypes.string,
};

export default Navigator;
