import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './SearchContent.scss';
import { nothingChanged, searchQueryChanged, updateSearchInput } from '../redux/actions';
import SearchField from '@adsk/nirvana-search-field';
import { T, prepareLangAttribute } from '../controller/locale';

// File name on Windows OS can't contain:  \ / : * ? " < > |
const regExp = new RegExp('(\\\\|\\/|\\:|\\*|\\?|\\"|<|>|\\|)', 'g');

export class SearchContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholder: T.translate('SC_SEARCH_PLACEHOLDER_TEXT'),
    };
    this._handleOnKeyDown = this._handleOnKeyDown.bind(this);
    this._handleOnClear = this._handleOnClear.bind(this);
    this._handleOnChange = this._handleOnChange.bind(this);
    this._handleOnSearch = this._handleOnSearch.bind(this);
    this._handleOnFocus = this._handleOnFocus.bind(this);
    this._handleOnBlur = this._handleOnBlur.bind(this);
  }

  componentDidMount() {
    // It feels weird that we depend on this call to trigger the initial search for the whole ContentDelivery component.
    this.props.nothingChanged();
  }

  _handleOnKeyDown(event) {
    if (event.key.toLowerCase() === 'enter') {
      this._handleOnSearch();
    }
  }

  async _handleOnClear() {
    const searchExpression = '';
    await this.props.updateSearchInput(searchExpression);
    this._handleOnSearch();
  }

  async _handleOnChange(value) {
    await this.props.updateSearchInput(value.replace(regExp, ''));
    if (this.props.searchQuery === '') {
      this._handleOnSearch();
    }
  }

  _handleOnSearch() {
    this.props.searchQueryChanged(this.props.searchQuery);
  }

  _handleOnFocus() {
    this.setState({ placeholder: '' });
  }

  _handleOnBlur() {
    this.setState({ placeholder: T.translate('SC_SEARCH_PLACEHOLDER_TEXT') });
  }

  render() {
    const lang = this.props.searchQuery ? prepareLangAttribute(this.props.filter) : null;
    return (
      <div className="display-search-box-area" lang={lang} id="disp-SEARCH-BOX">
        <SearchField
          className="display-search-box"
          value={this.props.searchQuery}
          onInputChange={this._handleOnChange}
          onClear={this._handleOnClear}
          onSearch={this._handleOnSearch}
          onFocus={this._handleOnFocus}
          onBlur={this._handleOnBlur}
          placeholder={this.state.placeholder}
          onKeyDown={this._handleOnKeyDown}
          maxLength={255}
        />
      </div>
    );
  }
}

SearchContent.propTypes = {
  searchQueryChanged: PropTypes.func,
  nothingChanged: PropTypes.func,
  searchQuery: PropTypes.string,
  updateSearchInput: PropTypes.func,
  filter: PropTypes.object,
};

const mapStateToProps = state => ({ searchQuery: state.searchQuery, filter: state.filter });
const mapDispatchToProps = dispatch => ({
  searchQueryChanged: searchQuery => dispatch(searchQueryChanged(searchQuery)),
  nothingChanged: () => dispatch(nothingChanged()),
  updateSearchInput: searchQuery => dispatch(updateSearchInput(searchQuery)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchContent);
