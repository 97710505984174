import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { facetedNavChanged, errorStatusChanged } from '../redux/actions';
import { T } from '../controller/locale';
import './BrowsingHierarchy.scss';
import Navigator from './Navigator';

export class BrowsingHierarchy extends Component {
  constructor(props) {
    super(props);
    this.hierarchyLength = 0;
    this.sortNodeNames = this.sortNodeNames.bind(this);
    this.generateParentChildRelation = this.generateParentChildRelation.bind(this);
    this.combineParentChildRelation = this.combineParentChildRelation.bind(this);
    this.filterUniquePaths = this.filterUniquePaths.bind(this);
    this.generateBrowsingHierarchy = this.generateBrowsingHierarchy.bind(this);
    this._handleChange = this._handleChange.bind(this);
  }

  sortNodeNames(a, b) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase(), this.props.filter.language);
  }

  /**
   * input (nodePath): [{id: "node 1", name: "Node 1", children: []},
   * {id: "node 2", name: "Node 2", children: [] }]
   * output (connections): [{id: "node 1", name: "Node 1", children: [{id: "node 2", name: "Node 2", children: [] }]},
   * {id: "node 2", name: "Node 2", children: [] }]
   */
  generateParentChildRelation(nodePath, connections) {
    if (nodePath.length) {
      for (let l = 0; l < nodePath.length - 1; l++) {
        nodePath[l].children.push(nodePath[l + 1]);
        connections.push(nodePath[l]);
      }
      connections.push(nodePath[nodePath.length - 1]);
    }
  }

  /**
   * input (connections): [{id: "node 1", name: "Node 1", children: [{id: "node 2", name: "Node 2", children: [] }]},
   * {id: "node 2", name: "Node 2", children: [] },
   * {id: "node 1", name: "Node 1", children: [{id: "node 4", name: "Node 4", children: [] }]},
   * {id: "node 4", name: "Node 4", children: [] }]
   * output (browsingHierarchy): [{id: "node 1", name: "Node 1",
   * children: [{id: "node 2", name: "Node 2", children: [] }, {id: "node 4", name: "Node 4", children: [] }]}]
   */
  combineParentChildRelation(connections, browsingHierarchy) {
    const hierarchyMap = {};
    for (const connection of connections) {
      if (!hierarchyMap[connection.id]) {
        hierarchyMap[connection.id] = connection;
      } else {
        if (Array.isArray(connection.children) && connection.children.length) {
          let isAlreadyChild = false;
          if (!hierarchyMap[connection.id].children.length) {
            hierarchyMap[connection.id].children.push(connection.children[0]);
          }
          else {
            for (const child of hierarchyMap[connection.id].children) {
              if (child.id === connection.children[0].id) {
                isAlreadyChild = true;
                break;
              }
            }
            if (!isAlreadyChild) {
              hierarchyMap[connection.id].children.push(connection.children[0]);
            }
          }
        }
      }
    }
    Object.keys(hierarchyMap).forEach(key => {
      //push only root nodes to display browsing hierarchy
      hierarchyMap[key].children.sort(this.sortNodeNames);
      if (hierarchyMap[key].isRoot) {
        browsingHierarchy.push(hierarchyMap[key]);
      }
    });
    browsingHierarchy.sort(this.sortNodeNames);
    this.hierarchyLength = browsingHierarchy.length;
  }

  filterUniquePaths(nodePath, uniquePaths, connections) {
    const nodePathString = nodePath.join();
    if (!uniquePaths.has(nodePathString)) {
      uniquePaths.add(nodePathString);
      const relations = [];
      for (let k = 0; k < nodePath.length; k++) {
        const relation = {};
        relation.id = nodePath.slice(0, k + 1).join('/');
        relation.name = nodePath[k];
        relation.children = [];
        (k === 0) ? relation.isRoot = true : relation.isRoot = false;
        relations.push(relation);
      }
      this.generateParentChildRelation(relations, connections);
    }
  }

  generateBrowsingHierarchy() {
    let browsingHierarchy = [];
    const connections = [];
    const uniquePaths = new Set();
    for (const browsingItemInfo of this.props.browsingItemsInfo) {
      if (this.props.facetedNav && browsingItemInfo.indexOf(this.props.facetedNav) === -1) {
        continue;
      }
      const pathArray = browsingItemInfo.split('/');
      if (Array.isArray(pathArray)) {
        this.filterUniquePaths(pathArray, uniquePaths, connections);
      }
    }
    this.combineParentChildRelation(connections, browsingHierarchy);
    // show `< All Results` only when a hierarchy node is selected
    if (browsingHierarchy.length && this.props.facetedNav) {
      const allResultsChildren = [];
      for (const hierarchyNode of browsingHierarchy) {
        if (hierarchyNode.isRoot) {
          allResultsChildren.push(hierarchyNode);
        }
      }
      const allResultsNode = {
        id: '',
        name: T.translate('BH_ALL_RESULTS_TEXT'),
        children: allResultsChildren,
        isRoot: true,
      };
      browsingHierarchy = [allResultsNode];
    }
    return browsingHierarchy;
  }

  _handleChange(data) {
    this.props.facetedNavChanged(data.id);
  }

  render() {
    return (
      <>
        <div className="display-tree-nodes">
          <Navigator filter={this.props.filter} navigationData={this.generateBrowsingHierarchy()} currentSelection={this.props.facetedNav} onSelectionChanged={this._handleChange} />
        </div>
        <div className="display-tree-label">
          {this.hierarchyLength ? <h4>{T.translate('BH_LABEL_RESULT')}</h4> : null}
        </div>
      </>
    );
  }
}

BrowsingHierarchy.propTypes = {
  browsingItemsInfo: PropTypes.array,
  facetedNav: PropTypes.string,
  facetedNavChanged: PropTypes.func,
  errorStatusChanged: PropTypes.func,
  filter: PropTypes.object,
};

const mapStateToProps = state => ({
  browsingItemsInfo: state.browsingItemsInfo,
  facetedNav: state.facetedNav,
  filter: state.filter,
});

const mapDispatchToProps = dispatch => ({
  facetedNavChanged: facetedNav => dispatch(facetedNavChanged(facetedNav)),
  errorStatusChanged: errorStatus => dispatch(errorStatusChanged(errorStatus)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrowsingHierarchy);
