import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Banner from '@hig/banner';
import Button from '@hig/button';
import Checkbox from '@hig/checkbox';
import Label from '@hig/label';
import { T } from '../controller/locale';
import config from '../controller/config';

class NotificationBanner extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDismissed: false,
      isPermanentlyDismissed: false,
      isPermanentlyDismissedChecked: false,
    };
    this._handleCheck = this._handleCheck.bind(this);
    this._handleNotificationDismiss = this._handleNotificationDismiss.bind(this);
  }

  async componentDidMount() {
    if (window.HostApp && window.HostApp.isNotificationPermanentlyDismissed) {
      const dismissedStatus = await window.HostApp.isNotificationPermanentlyDismissed();
      this.setState({ isPermanentlyDismissed: dismissedStatus });
    }
  }

  _handleCheck = () => {
    this.setState(prevState => ({
      isPermanentlyDismissedChecked: !prevState.isPermanentlyDismissedChecked,
    }));
  }

  _handleNotificationDismiss = () => {
    if (this.state.isPermanentlyDismissedChecked) {
      this.setState({ isPermanentlyDismissed: true });
      if (window.HostApp && window.HostApp.setNotificationDismissed) {
        window.HostApp.setNotificationDismissed();
      }
    }
    else {
      this.setState({ isDismissed: true });
    }
  }

  render() {
    return (
      <>
        {!this.state.isDismissed && !this.state.isPermanentlyDismissed &&
        this.props.checkedItems && Object.keys(this.props.checkedItems).length >= 50 ?
          <Banner type="primary"
            onDismiss={this._handleNotificationDismiss}
            dismissButtonTitle={T.translate('NB_NOTIFICATION_CLOSE')}
            actions={() => (
              <Banner.Interactions>
                <Banner.Action>
                  <a
                    style={{ marginLeft: '4px' }}
                    href={config.revitVersion >= 24 ?
                      'https://www.autodesk.com/revitcontent' : `https://knowledge.autodesk.com/support/revit-products/downloads/caas/downloads/content/autodesk-revit-20${config.revitVersion}-content.html`}
                    tabIndex={-1}
                  >
                    <Button
                      type="outline"
                      title={T.translate('NB_CONTENT_DOWNLOADS')}
                    />
                  </a>
                </Banner.Action>
                <Banner.Action>
                  <Checkbox
                    onClick={this._handleCheck}
                    onKeyDown={event => {
                      if (event.key.toLowerCase() === 'enter') {
                        this._handleCheck();
                      }
                    }}
                    checked={this.state.isPermanentlyDismissedChecked}
                  />
                  <Label>&nbsp;{T.translate('NB_PERMANENT_DISMISS')}</Label>
                </Banner.Action>
              </Banner.Interactions>
            )}
          >
            {T.translate('NB_NOTIFICATION_TEXT')}
          </Banner>
          : null}
      </>
    );
  }
}

NotificationBanner.propTypes = {
  checkedItems: PropTypes.object,
};

const mapStateToProps = state => ({
  checkedItems: state.checkedItems,
});

export default connect(
  mapStateToProps,
)(NotificationBanner);
