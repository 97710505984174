import React from 'react';
import { T } from './../controller/locale';
import emptyContentsImg from '../res/no-results.png';
import './NoResults.scss';

const NoResults = () => (
  <div className="empty-state">
    <img src={emptyContentsImg} height={150} />
    <h2>{T.translate('CD_NO_RESULTS_TITLE')}</h2>
    <span>{T.translate('CD_NO_RESULTS_DETAILS')}</span>
  </div>
);

export default NoResults;
