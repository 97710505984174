import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IconButton from '@hig/icon-button';
import { Grid24, List24, Globe24, Info24 } from '@hig/icons';
import Flyout, { anchorPoints } from '@hig/flyout';
import '@hig/flyout/build/index.css';
import ContentFilters from './ContentFilters';
import { ContentDisplayType } from './ContentDisplay';
import { toggleContentDisplayType, openAboutBox } from '../redux/actions';
import './FilterToggles.scss';
import { T } from '../controller/locale';

class FilterToggles extends Component {
  constructor(props) {
    super(props);
    this._handleToggleViewType = this._handleToggleViewType.bind(this);
    this._handleAboutBoxOpen = this._handleAboutBoxOpen.bind(this);
  }

  _handleToggleViewType() {
    this.props.toggleContentDisplayType();
  }

  _handleAboutBoxOpen() {
    this.props.openAboutBox(true);
  }

  render() {
    let viewToggleIcon = <Grid24 />;
    if (this.props.contentDisplayType === ContentDisplayType.GRID) {
      viewToggleIcon = <List24 />;
    }

    return (
      <React.Fragment>
        <div className="display-action-sections">
          <div className="display-action-locale-settings" id="btn-LOCALE-SETTINGS">
            <Flyout
              anchorPoints={anchorPoints.BOTTOM_CENTER}
              content={<ContentFilters />}
            >
              <IconButton
                type="transparent"
                icon={<Globe24 />}
                title={T.translate('FT_LOCALE_SETTINGS')}
              />
            </Flyout>
          </div>
          <div className="display-action-view-type" id="btn-VIEW-TYPE">
            <IconButton
              type="transparent"
              title={T.translate('FT_VIEW_TYPE')}
              icon={viewToggleIcon}
              onClick={this._handleToggleViewType}
            />
          </div>
          <div className="display-action-about-info" id="btn-ABOUT-INFO">
            <IconButton
              type="transparent"
              title={T.translate('FT_ABOUT_INFO')}
              icon={<Info24 />}
              onClick={this._handleAboutBoxOpen}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

FilterToggles.propTypes = {
  contentDisplayType: PropTypes.oneOf(Object.values(ContentDisplayType)),
  toggleContentDisplayType: PropTypes.func,
  openAboutBox: PropTypes.func,
};

const mapStateToProps = state => ({
  contentDisplayType: state.contentDisplayType,
});
const mapDispatchToProps = dispatch => ({
  toggleContentDisplayType: () => dispatch(toggleContentDisplayType()),
  openAboutBox: (open) => dispatch(openAboutBox(open)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterToggles);
