import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropdown from '@hig/dropdown';
import Label from '@hig/label';
import Spacer from '@hig/spacer';
import { metaChanged } from '../redux/actions';
import { connect } from 'react-redux';
import BrowsingHierarchy from './BrowsingHierarchy';
import { T, hostLanguage } from '../controller/locale';

export class MetadataFilters extends Component {
  constructor(props) {
    super(props);
  }

  defaultEnumOption = T.translate('MF_DEFAULT_OPTION');

  getSortEnumFn(translations) {
    const compFn = (aa, bb) => {
      const strAA = translations[aa];
      const strBB = translations[bb];
      if (strAA && strBB) {
        return strAA.toLowerCase().localeCompare(strBB.toLowerCase(), hostLanguage);
      }
      return aa.toLowerCase().localeCompare(bb.toLowerCase(), hostLanguage);
    };
    return compFn;
  }

  /**
   * Render an enumerated property as a Dropdown element.
   * A default 'Any' option is added automatically.
   */
  renderEnum(path, translation, options) {
    let translatedLabel = path;
    let translatedOptions = {};
    if (translation) {
      translatedLabel = T.translate(translation.label);
      if (translation.enum) {
        translatedOptions = translation.enum;
        options.sort(this.getSortEnumFn(translatedOptions));
      }
    }
    else {
      options.sort(); // Do we ever get here? Do we care?
    }

    let defaultValue = this.defaultEnumOption;
    if (this.props.metaData[path]) {
      defaultValue = this.props.metaData[path];
    }
    // dropdown for enumerated property
    return (<div key={path}>
      <Label>{translatedLabel}</Label>
      <Dropdown
        id="dropdown-CATEGORY"
        value={defaultValue}
        options={[this.defaultEnumOption, ...options]}
        formatOption={option => translatedOptions[option] || option}
        onChange={choice => {
          const update = { ...this.props.metaData };
          // contains the default option to Dropdown code, out of `this.state`
          if (choice === this.defaultEnumOption) {
            // prevents build-up of unused filters
            delete update[path];
          } else {
            update[path] = choice;
          }

          this.props.metaChanged(update);
        }}
      />
      <Spacer />
    </div>);
  }

  /**
   * Iterates through a product-defined set of properties,
   * rendering them as appropriate UI controls.
   */
  renderParamFilters() {
    const properties = this.props.filters.values;
    const queryCategories = this.props.queryCategories;
    return Object.keys(properties).map(key => {
      // grab translations, eg. { 'category': { enum: { '-100013': 'Doors', ... } } }
      const translation = this.props.filters.translations[key];
      if (translation) {
        return this.renderEnum(key, translation, queryCategories);
      } else {
        return null;
      }
    });
  }

  render() {
    return (
      <>
        <div>
          {this.renderParamFilters()}
        </div>
        <BrowsingHierarchy />
      </>
    );
  }
}

MetadataFilters.propTypes = {
  metaChanged: PropTypes.func,
  metaData: PropTypes.object,
  queryCategories: PropTypes.array,
  filters: PropTypes.object,
};

const mapStateToProps = state => ({
  metaData: state.metaData,
  queryCategories: state.itemsInfo.queryCategories,
});
const mapDispatchToProps = dispatch => ({
  metaChanged: metaData => dispatch(metaChanged(metaData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MetadataFilters);
