import T from 'i18n-react';
import config from './config';
import { logError } from './logError';

const defaultLanguage = 'en';
let hostLanguage;

const languages = {
  cs: require('../locale/cs.yml'),
  de: require('../locale/de.yml'),
  en: require('../locale/en.yml'),
  'en-gb': require('../locale/en-gb.yml'),
  es: require('../locale/es.yml'),
  fr: require('../locale/fr.yml'),
  hr: require('../locale/hr.yml'),
  it: require('../locale/it.yml'),
  ja: require('../locale/ja.yml'),
  ko: require('../locale/ko.yml'),
  pl: require('../locale/pl.yml'),
  'pt-br': require('../locale/pt-br.yml'),
  ru: require('../locale/ru.yml'),
  'zh-hans': require('../locale/zh-Hans.yml'),
  'zh-hant': require('../locale/zh-hant.yml'),
};

/**
 * @param {string} lang Language
 */
export const setLanguage = lang => {
  if (hostLanguage === lang) {
    return; // Small optimization. Maybe.
  }
  hostLanguage = lang;

  if (!languages[lang]) {
    logError('Unknown language: ' + lang);
  }
  T.setTexts({ ...T.texts, ...languages[defaultLanguage], ...languages[lang] });
};

export { T, hostLanguage };

const en_2021 = ['US_Imperial', 'US_Metric', 'AU', 'CA', 'IN', 'NZ', 'SG', 'ZA', 'GB'];
const ru_2021 = ['RU', 'INTL'];

const languageRegionFullTable = {
  'zh-CN': ['CN', 'INTL'],
  'zh-TW': ['TW', 'INTL'],
  cs: ['CZ', 'INTL'],
  en: ['US_Imperial', 'US_Metric', 'AU', 'BE', 'CA', 'DK', 'EURO', 'EG', 'FI', 'GR', 'HK', 'IS', 'IN', 'MY', 'NL', 'NZ', 'NO', 'PT', 'RO', 'SG', 'ZA', 'SOA', 'SE', 'TH', 'GB'],
  fr: ['FR', 'INTL'],
  de: ['DE'],
  it: ['IT', 'INTL'],
  ja: ['JP', 'INTL'], // Add 'RUG' if ever needed.
  ko: ['KR', 'INTL'],
  pl: ['PL', 'INTL'],
  'pt-BR': ['BR', 'INTL'],
  ru: ['RU', 'BY', 'UA', 'INTL'],
  es: ['ES', 'INTL'],
};
let languageRegionFullTableInitialized = false;

export const languageRegionFull = () => {
  if (!languageRegionFullTableInitialized) {
    if (config.revitVersion < 23) { // Remove 'EURO' from the pre 2023 library
      languageRegionFullTable.en = languageRegionFullTable.en.filter((val) => val !== 'EURO');
    }
    if (config.revitVersion === 21) {
      languageRegionFullTable.en = en_2021;
      languageRegionFullTable.ru = ru_2021;
    }
    languageRegionFullTableInitialized = true;
  }
  return languageRegionFullTable;
};

const subLibraries = {
  'zh-CN_CN': 'Chinese',
  'zh-CN_INTL': 'Chinese_INTL',
  'zh-TW_TW': 'Traditional Chinese',
  'zh-TW_INTL': 'Traditional Chinese_INTL',
  cs_CZ: 'Czech',
  cs_INTL: 'Czech_INTL',
  en_US_Imperial: 'English-Imperial',
  en_US_Metric: 'English',
  en_AU: 'Australia',
  en_BE: 'Belgium',
  en_CA: 'Canada',
  en_DK: 'Denmark',
  en_EURO: 'EURO',
  en_EG: 'Egypt',
  en_FI: 'Finland',
  en_GR: 'Greece',
  en_HK: 'Hong Kong',
  en_IS: 'Iceland',
  en_IN: 'India',
  en_MY: 'Malaysia',
  en_NL: 'Netherlands',
  en_NZ: 'New Zealand',
  en_NO: 'Norway',
  en_PT: 'Portugal',
  en_RO: 'Romania',
  en_SG: 'Singapore',
  en_ZA: 'South Africa',
  en_SOA: 'South Asia',
  en_SE: 'Sweden',
  en_TH: 'Thailand',
  en_GB: 'UK',
  fr_FR: 'French',
  fr_INTL: 'French_INTL',
  de_DE: 'German',
  it_IT: 'Italian',
  it_INTL: 'Italian_INTL',
  ja_JP: 'Japanese',
  ja_INTL: 'Japanese_INTL',
  // ja_RUG: 'Japanese_RUG',
  ko_KR: 'Korean',
  ko_INTL: 'Korean_INTL',
  'pt-BR_BR': 'Portuguese',
  'pt-BR_INTL': 'Portuguese_INTL',
  pl_PL: 'Polish',
  pl_INTL: 'Polish_INTL',
  ru_BY: 'Belarus',
  ru_RU: 'Russian',
  ru_INTL: 'Russian_INTL',
  ru_UA: 'Ukraine',
  es_ES: 'Spanish',
  es_INTL: 'Spanish_INTL',
};

export const subLibraryNameFromLocale = (language, region) => {
  const locale = [language, region].join('_');
  const subLibraryName = subLibraries[locale];
  if (!subLibraryName) {
    return 'US Imperial';
  }

  return subLibraryName;
};

export const prepareLangAttribute = (filter) => {
  if (!filter || !filter.language) {
    logError('Invalid filter');
    return null;
  }

  if (filter.language !== 'ja' &&
    filter.language !== 'ko' &&
    filter.language !== 'zh-hans' &&
    filter.language !== 'zh-hant') {
    return null;
  }

  return filter.language;
};

const languageRegionLTTable = {
  'zh-CN': ['CN'],
  'zh-TW': ['TW'],
  cs: ['CZ'],
  en: ['US_Imperial', 'US_Metric', 'AU', 'NZ', 'SG', 'SOA', 'GB'],
  fr: ['FR'],
  de: ['DE'],
  it: ['IT'],
  ja: ['JP'],
  ko: ['KR'],
  pl: ['PL'],
  'pt-BR': ['BR'],
  ru: ['RU'],
  es: ['ES'],
};

let languageRegionLTTableInitialized = false;
export const languageRegionLT = () => {
  if (!languageRegionLTTableInitialized) {
    if (config.revitVersion === 21) { // Remove 'SOA' from the pre 2022 library
      languageRegionLTTable.en = languageRegionLTTable.en.filter((val) => val !== 'SOA');
    }
    languageRegionLTTableInitialized = true;
  }
  return languageRegionLTTable;
};

export const defaultRegion = (language, product) => {
  if (product === 'LT') {
    return language in languageRegionLT() ? languageRegionLT()[language][0] : null;
  }
  return language in languageRegionFull() ? languageRegionFull()[language][0] : null;
};
