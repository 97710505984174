import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Tooltip from '@adsk/nirvana-tooltip';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProgressRing from '@hig/progress-ring';
import cssVars from '../variables.scss';
import { prepareLangAttribute } from '../controller/locale';
import makeSafeId from './makeSafeId';
import Checkbox from '@hig/checkbox';

const gridColumnSize = parseInt(cssVars.gridColumnSize);
const gridGap = parseInt(cssVars.gridGap);
const gridPadding = parseInt(cssVars.gridPadding);

class ContentDisplayGrid extends Component {

  static propTypes = {
    items: PropTypes.array,
    selectedItem: PropTypes.object,
    checkedItems: PropTypes.object,
    hasMoreItems: PropTypes.func,
    loadMoreItems: PropTypes.func,
    performSelection: PropTypes.func,
    doMove: PropTypes.func,
    filter: PropTypes.object,
  };

  calculateElemsPerRow() {
    if (document.getElementsByClassName('display-content-grid')[0] !== undefined) {
      // calculate the width of content grid without padding: thus, subtracting 2 * gridPadding
      const gridWidth = document.getElementsByClassName('display-content-grid')[0].clientWidth - (2 * gridPadding);
      // each grid's width is 100px and grid gap is 10px
      // 100 * elementsPerRow + 10 * (elementsPerRow - 1) = gridWidth
      // 2 elements per row falls in the range of gridWidth >= 210px && gridWidth < 319px
      return Math.floor((gridWidth + gridGap) / (gridColumnSize + gridGap));
    }
    return 0;
  }

  isContentLessThanWindowSize() {
    const scrollDiv = document.getElementsByClassName('display-scrollable-div')[0];
    const contentGrid = document.getElementsByClassName('display-content-grid')[0];
    if (scrollDiv !== undefined && contentGrid !== undefined) {
      return scrollDiv.clientHeight > contentGrid.clientHeight;
    }
    return false;
  }

  async componentDidUpdate() {
    if (this.isContentLessThanWindowSize() && this.props.hasMoreItems()) {
      this.props.loadMoreItems();
    }
  }

  createDisplayItems() {
    const displayItems = this.props.items.map((item, index) => (
      <div
        id={'cont-disp-grid-' + makeSafeId(item.description)}
        key={item.contentId}
        className="display-content-grid-item"
        style={{ width: item.preview.width }}
        tabIndex={index === 0 ? 0 : -1}
        onClick={event => this.props.doMove(index, event.shiftKey, item)}
        onKeyDown={event => {
          if (event.key.toLowerCase() === 'enter') {
            this.props.performSelection(item);
          }
          else if (event.which >= 37 && event.which <= 40) {
            let newIndex = index;
            if (event.which === 37) {
              newIndex -= 1;
            } else if (event.which === 38) {
              newIndex -= this.calculateElemsPerRow();
            } else if (event.which === 39) {
              newIndex += 1;
            } else if (event.which === 40) {
              newIndex += this.calculateElemsPerRow();
            }
            if (newIndex >= 0 && newIndex < this.props.items.length) {
              const newItem = this.props.items[newIndex];
              document.getElementById('cont-disp-grid-' + makeSafeId(newItem.description)).focus();
              this.props.doMove(newIndex, event.shiftKey);
            }
          }
        }}
      >
        <div
          className="display-content-preview-load"
          style={{ height: item.preview.height }}
        >
          {(window.HostApp && window.HostApp.onLoadMulti) ?
            <div className={
              (this.props.checkedItems && Object.keys(this.props.checkedItems).length === 0) ? 'display-checkbox-hover' : 'display-checkbox-checked'
            }>
              <Checkbox
                tabIndex={-1}
                checked={this.props.checkedItems[item.contentId] !== undefined}
              />
            </div>
            : null}

          <img
            className="display-content-preview-item"
            src={item.preview.url}
            width={item.preview.width}
            height={item.preview.height}
          />
        </div>
        {item.contentId === this.props.selectedItem.contentId ?
          <div className="display-content-preview-load-selected" />
          : null}
        <Tooltip
          style={{ wordBreak: 'break-all' }}
          placement={Tooltip.Placements.BOTTOM}
          theme={Tooltip.Themes.LIGHT}
          content={item.description}>
          <div className="display-content-item-title">
            {item.name}
          </div>
        </Tooltip>
      </div>
    ));
    return displayItems;
  }

  render() {
    const lang = prepareLangAttribute(this.props.filter);
    return (
      <>
        <div id="scrollableDivGrid" className="display-scrollable-div">
          <InfiniteScroll
            dataLength={this.props.items.length}
            next={this.props.loadMoreItems}
            hasMore={this.props.hasMoreItems()}
            scrollableTarget="scrollableDivGrid"
            loader={<ProgressRing />}
          >
            {<div className="display-content-grid" lang={lang}>{this.createDisplayItems()}</div>}
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  items: state.itemsInfo.items,
  selectedItem: state.selectedItem,
  checkedItems: state.checkedItems,
  filter: state.filter,
});

export default connect(
  mapStateToProps,
)(ContentDisplayGrid);
