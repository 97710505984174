import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '@hig/modal';
import ProgressBar from '@hig/progress-bar';
import Button, { widths } from '@hig/button';
import { T } from '../controller/locale';
import makeSafeId from './makeSafeId';
import './ProgressDialog.scss';
import RichText from '@hig/rich-text';
import { openProgressDialog } from '../redux/actions';
import { connect } from 'react-redux';
import fcsManager from './../controller/fcsManager';

const modalStyles = styles => {
  return {
    ...styles,
    modal: {
      ...styles.modal,
      window: {
        ...styles.modal.window,
        width: '500px',
        height: '400px',
      },
    },
  };
};

class ProgressDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastProgressUpdate: 0,
      currentProgress: 0,
      isConnectionOk: true,
    };
    this._handleFailure = this._handleFailure.bind(this);
    this._handleProgressCancel = this._handleProgressCancel.bind(this);
  }

  async componentDidMount() {
    setInterval((function() {
      if (this.state.lastProgressUpdate === this.state.currentProgress || this.state.lastProgressUpdate === 0) {
        fcsManager.accessToInternet().then(goodConnection => {
          this.setState({ isConnectionOk: goodConnection });
        }).catch(() => {
          this.setState({ isConnectionOk: false });
        });
      }
      else {
        this.setState({ lastProgressUpdate: this.state.currentProgress });
      }
    }).bind(this), 2 * 60 * 1000); // Check internet every 2 mins
  }

  _handleProgressCancel() {
    this.props.onClearDownloadedFiles();
  }

  _handleFailure() {
    this.props.openProgressDialog(false);
    this.props.onProceedWithFamilyLoad();
  }

  formatFailures = failedItems => {
    let result = '';
    for (const failedItem of failedItems) {
      result += '<p>' + failedItem + '</p>';
    }
    return result;
  };

  // Update the state just before we re-render
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.progressValue && prevState.currentProgress !== nextProps.progressValue) {
      return {
        currentProgress: nextProps.progressValue,
        isConnectionOk: true,
      };
    }
    // Return null to indicate no change to state.
    return null;
  }

  render() {
    if (this.props.failedItems.length > 0) {
      return (
        <Modal
          open={this.props.shouldShowProgress}
          title={T.translate('PD_TITLE')}
          closeButtonAriaLabel={T.translate('PD_CLOSE')}
          onCloseClick={this._handleFailure}
          stylesheet={modalStyles}
        >
          <div className="failed-item-list">
            <p>{T.translate('PD_DOWNLOAD_FAILURE')}</p>
            <RichText dangerouslySetInnerHTML={{ __html: this.formatFailures(this.props.failedItems) }} />
          </div>
        </Modal >);
    }
    else {
      return (
        <Modal
          open={this.props.shouldShowProgress}
          title={T.translate('PD_TITLE')}
          closeButtonAriaLabel={T.translate('PD_CLOSE')}
          onCloseClick={this._handleProgressCancel}
          stylesheet={modalStyles}
        >
          <div className="progress-bar">
            <ProgressBar percentComplete={this.props.progressValue} />
            <p>{this.state.isConnectionOk ? this.props.progressValue + '%' : T.translate('CD_ERROR_NO_INTERNET')}</p>
          </div>
          <div className="progress-cancel-button">
            <Button
              type="primary"
              title={T.translate('PD_BUTTON_CANCEL')}
              id={makeSafeId('btn-PROGRESS_DLG-CANCEL')}
              onClick={this._handleProgressCancel}
              width={widths.GROW}
            />
          </div>
        </Modal >);
    }
  }
}

ProgressDialog.propTypes = {
  shouldShowProgress: PropTypes.bool,
  openProgressDialog: PropTypes.func,
  progressValue: PropTypes.number,
  failedItems: PropTypes.array,
  onClearDownloadedFiles: PropTypes.func,
  onProceedWithFamilyLoad: PropTypes.func,
};

const mapStateToProps = state => ({
  shouldShowProgress: state.shouldShowProgress,
});

const mapDispatchToProps = dispatch => ({
  openProgressDialog: shouldShowProgress => dispatch(openProgressDialog(shouldShowProgress)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgressDialog);


