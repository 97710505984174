import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import rootReducer from './redux/reducers';
import { initFilterFromLanguage } from './view/ContentFilters';
import { ContentDisplayType } from './view/ContentDisplay';
import { evaluateSearchCriteria } from './view/util';
import ContentDeliveryInner from './ContentDeliveryInner';
import { FreezeMode, openProgressDialog } from './redux/actions';
import { setLanguage } from './controller/locale';
import { handleFailures } from './view/util';
import { logError } from './controller/logError';

export { default as fcsManager } from './controller/fcsManager';
export { default as config } from './controller/config';

const deserialize = (storedData) => {
  if (storedData) {
    try {
      const subState = JSON.parse(storedData);
      // this.store.dispatch(restoreState(subState));
      return subState;
    }
    catch (err) {
      logError(err);
    }
  }
  return {};
};

const serializeAll = (state) => {
  const { filter, contentDisplayType, metaData, facetedNav, searchQuery, history } = state;
  return JSON.stringify({ filter, metaData, facetedNav, searchQuery, contentDisplayType, history });
};


export default class ContentDelivery extends React.Component {
  constructor(props) {
    super(props);
    // If the caller knows the language at construction-time, then great! Otherwise, the
    // caller is free to setLanguage() later and trigger re-rendering. Or not even render
    // us before language is known.
    setLanguage(props.language);

    this.store = createStore(
      rootReducer,
      {
        ...{
          filter: initFilterFromLanguage(this.props.language, this.props.product),
          product: this.props.product,
          itemsInfo: { items: [], totalCount: 0, queryCategories: [], totalPages: 0 },
          currentPage: 0,
          selectedItem: {},
          lastSelectedIndex: 0,
          checkedItems: {},
          metaData: {},
          browsingItemsInfo: [],
          facetedNav: '',
          searchQuery: '',
          history: { past: [], present: {}, future: [] },
          contentDisplayType: ContentDisplayType.GRID,
          showBottle: false,
          loading: { mode: FreezeMode.UNFROZEN },
          errorStatus: { show: false, text: '', goodConnection: true },
          shouldOpenAboutBox: false,
          shouldShowProgress: false,
        },
        // Caller _must_ make sure serializedData is good at construction time.
        ...deserialize(this.props.serializedData),
      },
      applyMiddleware(evaluateSearchCriteria, ReduxThunk),
    );
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  getSelectedItem() {
    return this.store.getState().selectedItem;
  }

  getCheckedItems() {
    return this.store.getState().checkedItems;
  }

  postError() {
    handleFailures(this.store);
  }

  serialize() {
    return serializeAll(this.store.getState());
  }

  startDownload() {
    this.store.dispatch(openProgressDialog(true));
  }

  render() {
    return (
      <Provider store={this.store}>
        <ContentDeliveryInner {...this.props} />
      </Provider>
    );
  }

  static propTypes = ContentDeliveryInner.propTypes;
}
