import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dropdown from '@hig/dropdown';
import '@hig/fonts/build/ArtifaktElement.css';
import Label from '@hig/label';
import Spacer from '@hig/spacer';
import './ContentFilters.scss';
import { filterChanged } from '../redux/actions';
import { logError } from '../controller/logError';
import { T, hostLanguage, languageRegionFull, languageRegionLT, defaultRegion } from '../controller/locale';


// only used when Revit starts; need for a coversion if the
// language code does not match with the language returned by Revit API
// returning region for en_GB case: defaultRegion always default to 'US' for 'en'
function convertLanguageRegion(language, region) {
  switch (language) {
    case 'zh-hans':
      return { language: 'zh-CN', region: 'CN' };
    case 'zh-hant':
      return { language: 'zh-TW', region: 'TW' };
    case 'pt-br':
      return { language: 'pt-BR', region: 'BR' };
    case 'en-gb':
      return { language: 'en', region: 'GB' };
    default:
      return { language, region };
  }
}

export function initFilterFromLanguage(lang, product) {
  const reg = defaultRegion(lang, product);
  const { language, region } = convertLanguageRegion(lang, reg);
  if (!region) {
    logError('Invalid data for initial filter. Using US defaults.');
    return { language: 'en', region: 'US_Imperial' };
  }

  return { language, region };
}

export class ContentFilters extends Component {
  constructor(props) {
    super(props);
    this.state = { ...props.filter };
    this.sortLanguageRegion = this.sortLanguageRegion.bind(this);
    this._handleLanguage = this._handleLanguage.bind(this);
    this._handleRegion = this._handleRegion.bind(this);
  }

  sortLanguageRegion(aa, bb) {
    const strAA = T.translate(aa);
    const strBB = T.translate(bb);
    return strAA.toLowerCase().localeCompare(strBB.toLowerCase(), hostLanguage);
  }

  populateLanguageDropDown() {
    return Object.keys(languageRegionFull()).sort(this.sortLanguageRegion);
  }

  _handleLanguage(language) {
    const region = defaultRegion(language, this.props.product);
    this.setState({ language });
    this._handleRegion(region);
  }

  _handleRegion(region) {
    this.setState({ region }, () => {
      this.props.filterChanged(this.state);
    });
  }

  populateRegionDropDown() {
    const languageRegion = this.props.product === 'LT' ? languageRegionLT() : languageRegionFull();
    if (this.state.language === 'en') {
      const enRegions = ['US_Imperial', 'US_Metric'];
      //sort all the regions for English except US_Imperial & US_Metric
      const sortedEnRegions = Object.values(languageRegion['en']).slice(2).sort(this.sortLanguageRegion);
      return enRegions.concat(sortedEnRegions);
    }
    return languageRegion[this.state.language];
  }

  formatDropdownOption(option) {
    return T.translate(option);
  }

  render() {
    return (
      <div className="display-filters-main">
        <Label>{T.translate('CF_DROPDOWN_LANGUAGE')}</Label>
        <Dropdown
          id="dropdown-LANGUAGE"
          value={this.state.language}
          options={this.populateLanguageDropDown()}
          onChange={this._handleLanguage}
          formatOption={this.formatDropdownOption}
        />

        <Spacer />

        <Label>{T.translate('CF_DROPDOWN_REGION')}</Label>
        <Dropdown
          id="dropdown-REGION"
          options={this.populateRegionDropDown()}
          value={this.state.region}
          onChange={this._handleRegion}
          formatOption={this.formatDropdownOption}
        />
      </div>
    );
  }
}

ContentFilters.propTypes = {
  filterChanged: PropTypes.func,
  filter: PropTypes.object,
  product: PropTypes.string,
};

const mapStateToProps = state => ({ filter: state.filter, product: state.product });
const mapDispatchToProps = dispatch => ({
  filterChanged: filter => dispatch(filterChanged(filter)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentFilters);
