import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '@hig/modal';
import RichText from '@hig/rich-text';
import '@hig/fonts/build/ArtifaktElement.css';
import aboutInfoTemplate from '../generated/about-box.html';
import { openAboutBox } from '../redux/actions';
import { T } from '../controller/locale';
import config from '../controller/config';

const modalStyles = styles => {
  return {
    ...styles,
    modal: {
      ...styles.modal,
      window: {
        ...styles.modal.window,
        width: '500px',
        height: '400px',
      },
    },
  };
};

class AboutBox extends Component {
  constructor(props) {
    super(props);
    this._handleAboutBoxClose = this._handleAboutBoxClose.bind(this);
  }

  _handleAboutBoxClose() {
    this.props.openAboutBox(false);
  }

  render() {
    let aboutInfo = aboutInfoTemplate.replace('645751', new Date().getFullYear());
    if (config.revitVersion < 22) {
      // Revit 2021 doesn't open links in the default browser
      aboutInfo = aboutInfo.replace(/<a[^<>]*href\s?=\s?['"]*([^'"]*)[^<>]*>((.(?!\<\/a\>))*.)<\/a>/g, '$2 &lt;$1&gt;');
    }
    return (
      <div style={!this.props.shouldOpenAboutBox ? { visibility: 'hidden' } : null}>
        <Modal
          open={this.props.shouldOpenAboutBox}
          title={T.translate('AB_TITLE')}
          closeButtonAriaLabel={T.translate('AB_CLOSE')}
          onCloseClick={this._handleAboutBoxClose}
          stylesheet={modalStyles}
        >
          <RichText dangerouslySetInnerHTML={{ __html: aboutInfo }} />
        </Modal >
      </div>
    );
  }
}

AboutBox.propTypes = {
  shouldOpenAboutBox: PropTypes.bool,
  openAboutBox: PropTypes.func,
};

const mapStateToProps = state => ({
  shouldOpenAboutBox: state.shouldOpenAboutBox,
});

const mapDispatchToProps = dispatch => ({
  openAboutBox: shouldOpenAboutBox => dispatch(openAboutBox(shouldOpenAboutBox)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutBox);
