import fcsManager from './../controller/fcsManager';
import { logError } from '../controller/logError';
import { browsingItemsInfoChanged, itemsInfoChanged, itemsInfoPageChanged, freezeApp, FreezeMode, errorStatusChanged, showBottleChanged, historyChanged } from '../redux/actions';
import { T, subLibraryNameFromLocale } from '../controller/locale';

const prepareItems = items => items.filter(item => item.preview && item.preview.url ? true : logError('invalid item' + item));

const updateTab = (pastTab, key, value) => {
  const presentTab = {};
  for (const [k, val] of Object.entries(pastTab)) {
    if (k !== key) {
      presentTab[k] = val;
    }
  }
  presentTab[key] = value;
  return presentTab;
};

const prepareHistoryObject = (store, key, value) => {
  const { searchQuery, metaData, facetedNav, history } = store.getState();
  let presentHistory = {};
  if (!history.past.length) {
    presentHistory = { searchQuery, facetedNav, metaData };
  }
  else {
    const pastTab = history.present;
    const presentTab = updateTab(pastTab, key, value);
    presentHistory = presentTab;
  }
  return presentHistory;
};

const prepareHistory = (action, store) => {
  const { searchQuery, metaData, facetedNav, history } = store.getState();
  let presentHistory = {};
  switch (action) {
    case 'SEARCH_QUERY_CHANGED':
      presentHistory = prepareHistoryObject(store, 'searchQuery', searchQuery);
      break;
    case 'FNAV_CHANGED':
      presentHistory = prepareHistoryObject(store, 'facetedNav', facetedNav);
      break;
    case 'META_CHANGED':
      presentHistory = prepareHistoryObject(store, 'metaData', metaData);
      break;
    case 'REFRESH_SEARCH':
      if (searchQuery !== history.present.searchQuery) {
        presentHistory = prepareHistoryObject(store, 'searchQuery', searchQuery);
      }
      else if (facetedNav !== history.present.facetedNav) {
        presentHistory = prepareHistoryObject(store, 'facetedNav', facetedNav);
      }
      else if (metaData.category !== history.present.metaData.category) {
        presentHistory = prepareHistoryObject(store, 'metaData', metaData);
      }
      break;
    default:
  }
  store.dispatch(historyChanged({
    past: [...history.past, history.present],
    present: presentHistory,
    future: [],
  }));
};

export const handleFailures = async (store) => {
  const goodConnection = await fcsManager.accessToInternet();
  const text = goodConnection ? T.translate('CD_ERROR_NO_SERVICE') : T.translate('CD_ERROR_NO_INTERNET');
  store.dispatch(errorStatusChanged({ show: true, text, goodConnection }));
};

const searchItems = async (store, filter, isTopLevel, action) => {
  const { searchQuery, currentPage, itemsInfo, browsingItemsInfo, facetedNav, metaData, history } = store.getState();

  if (currentPage === 0) {
    store.dispatch(freezeApp(FreezeMode.SEARCH_PROGRESS));
  }

  try {
    const res = await fcsManager.searchContentFromLibrary(searchQuery, currentPage, filter);
    let items = prepareItems(res.items);
    let itemsInfoAction = itemsInfoChanged;
    if (action.type === 'CURRENT_PAGE_CHANGED') {
      items = itemsInfo.items.concat(items);
      itemsInfoAction = itemsInfoPageChanged;
    }
    store.dispatch(itemsInfoAction({
      items,
      queryCategories: res.queryCategories,
      totalCount: res.totalCount,
      totalPages: res.totalPages,
    }));
    if (items.length === 0) {
      store.dispatch(showBottleChanged(true));
    }
    else {
      store.dispatch(showBottleChanged(false));
    }

    const filteredBrowsingInfo = res.browsingInfo.map(item => {
      if (isTopLevel) {
        const itemAtoms = item.split('/');
        return itemAtoms[0];
      }
      else {
        return item;
      }
    });

    store.dispatch(browsingItemsInfoChanged(browsingItemsInfo.concat(filteredBrowsingInfo)));
    store.dispatch(errorStatusChanged({ show: false, text: '', goodConnection: true }));

    // FILTER_CHANGED, HOME_RESET, NOTHING_CHANGED & history is null: Clear past and future history, set present history
    // NOTHING_CHANGED & history is not null: don't dispatch if history already exists - retain state on load case
    if (['FILTER_CHANGED', 'HOME_RESET'].includes(action.type) ||
      (action.type === 'NOTHING_CHANGED' && Object.keys(history.present).length === 0)) {
      store.dispatch(historyChanged({
        past: [],
        present: { searchQuery, facetedNav, metaData },
        future: [],
      }));
    }

    // Insert the present at the end of the past
    // Set the present to the new state after handling the action
    // Clear the future
    if (['SEARCH_QUERY_CHANGED', 'FNAV_CHANGED', 'META_CHANGED', 'REFRESH_SEARCH'].includes(action.type)) {
      const { history } = store.getState();
      // avoid duplicating the history if the search is repeated
      if ((searchQuery !== history.present.searchQuery) ||
        (facetedNav !== history.present.facetedNav) ||
        (metaData.category !== history.present.metaData.category)) {
        prepareHistory(action.type, store);
      }
    }

    // This "syncs" the journal, so we don't replay the next set of events before this is done.
    if (window.HostApp && window.HostApp.journalEvent) {
      window.HostApp.journalEvent('Search Completed');
    }
  }
  catch (err) {
    store.dispatch(showBottleChanged(false));
    logError(err);
    handleFailures(store);
  }

  store.dispatch(freezeApp(FreezeMode.UNFROZEN));
};

export const evaluateSearchCriteria = store => next => action => {
  const result = next(action);
  const { searchQuery, filter, metaData, facetedNav, browsingItemsInfo, product } = store.getState();
  const changeActions = [
    'SEARCH_QUERY_CHANGED',
    'FILTER_CHANGED',
    'FNAV_CHANGED',
    'META_CHANGED',
    'HOME_RESET',
    'CURRENT_PAGE_CHANGED',
    'NOTHING_CHANGED',
    'PAST_FUTURE_HISTORY_CHANGED',
    'REFRESH_SEARCH',
  ];

  const localFilter = { language: filter.language };

  //To handle Imperial, Metric and International content
  localFilter.revitRegion = subLibraryNameFromLocale(filter.language, filter.region);

  if (product === 'LT') {
    localFilter.ltCompatible = true;
  }

  if (metaData.category) {
    localFilter.category = metaData.category;
  }

  if (facetedNav) {
    localFilter.hierarchy = facetedNav;
  }

  const filtersEmpty = facetedNav === '' && searchQuery === '' && !metaData.category;
  const duringInfinteScrolling = action.type === 'CURRENT_PAGE_CHANGED'; // special case to keep top level nodes same during infinite scrolling
  if (filtersEmpty && (duringInfinteScrolling || (browsingItemsInfo.length === 0 && changeActions.includes(action.type)))) {
    searchItems(store, localFilter, true, action);
  }
  else if (changeActions.includes(action.type)) {
    searchItems(store, localFilter, false, action);
  }

  return result;
};
