import React from 'react';
import { connect } from 'react-redux';
import { itemSelectedChanged, itemsCheckedChanged, lastSelectedIndexChanged, currentPageChanged } from '../redux/actions';
import PropTypes from 'prop-types';
import ProgressRing from '@hig/progress-ring';
import './ContentDisplay.scss';
import ContentDisplayGrid from './ContentDisplayGrid';
import ContentDisplayList from './ContentDisplayList';
import { FreezeMode } from '../redux/actions';
import NoResults from './NoResults';

export const ContentDisplayType = {
  GRID: 1,
  LIST: 2,
};

class ContentDisplay extends React.Component {
  static propTypes = {
    displayType: PropTypes.oneOf(Object.values(ContentDisplayType)),
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    allowDeleteSelection: PropTypes.bool,
    showBottle: PropTypes.bool,
    loading: PropTypes.object,
    itemSelectedChanged: PropTypes.func,
    lastSelectedIndex: PropTypes.number,
    lastSelectedIndexChanged: PropTypes.func,
    itemsCheckedChanged: PropTypes.func,
    currentPageChanged: PropTypes.func,
    checkedItems: PropTypes.object,
    items: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.hasMoreItems = this.hasMoreItems.bind(this);
    this.loadMoreItems = this.loadMoreItems.bind(this);
    this.performSelection = this.performSelection.bind(this);
    this.doMove = this.doMove.bind(this);
  }

  hasMoreItems() {
    return (this.props.currentPage < this.props.totalPages - 1);
  }

  loadMoreItems() {
    if (this.hasMoreItems) {
      this.props.currentPageChanged(this.props.currentPage + 1);
    }
  }

  // Handles shift+click and shift+arrows.
  doShiftSelection(itemIndex, lastSelectedIndex) {
    if (itemIndex < 0 || itemIndex >= this.props.items.length ||
        lastSelectedIndex < 0 || lastSelectedIndex >= this.props.items.length) {
      return;
    }
    const newCheckedItems = {};
    for (let i = Math.min(itemIndex, lastSelectedIndex); i <= Math.max(itemIndex, lastSelectedIndex); i++) {
      const currItem = this.props.items[i];
      if (currItem && currItem.contentId !== undefined) {
        newCheckedItems[currItem.contentId] = currItem;
      }
    }
    // Replace the old selection with 'newCheckedItems'.
    this.props.itemsCheckedChanged(newCheckedItems);
  }

  // itemToSelect - when 'defined' and '!shiftKey' this item's selection will be toggled
  doMove(newIndex, shiftKey, itemToSelect) {
    if (window.HostApp && window.HostApp.onLoadMulti && shiftKey) {
      this.doShiftSelection(newIndex, this.props.lastSelectedIndex);
      // Note: don't update 'lastSelectedIndex' if shift is pressed.
    } else {
      if (itemToSelect && itemToSelect.contentId) {
        this.performSelection(itemToSelect);
      }
      // Update 'lastSelectedIndex' after click.
      this.props.lastSelectedIndexChanged(newIndex);
    }
  }

  // Handles a click or Enter key.  Does not handle shift+select.
  _handleOnMultiClicks(item) {
    if (item.contentId) {
      let newCheckedItems = {};
      if (this.props.checkedItems[item.contentId] !== undefined) {
        delete this.props.checkedItems[item.contentId]; //item unselected
        newCheckedItems = { ...this.props.checkedItems };
      }
      else {
        newCheckedItems = { ...this.props.checkedItems, [item.contentId]: item }; //item selected
      }
      this.props.itemsCheckedChanged(newCheckedItems);
    }
  }

  performSelection(item) {
    if (window.HostApp && window.HostApp.onLoadMulti) {
      // Toggle the selection of exactly 1 item.
      this._handleOnMultiClicks(item);
    }
    else {
      // Select the item.
      this.props.itemSelectedChanged(item);
    }
  }

  render() {
    let Component;
    if (this.props.displayType === ContentDisplayType.GRID) {
      Component = ContentDisplayGrid;
    } else {
      Component = ContentDisplayList;
    }
    const content = (<Component
      allowDeleteSelection={this.props.allowDeleteSelection}
      hasMoreItems={this.hasMoreItems}
      loadMoreItems={this.loadMoreItems}
      performSelection={this.performSelection}
      doMove={this.doMove}
    />);

    if (this.props.loading.mode === FreezeMode.SEARCH_PROGRESS) {
      return (<div className="display-content-loading-container">
        {content}
        <div className="display-content-loading-overlay display-content-loading-status--searching">
          <ProgressRing size="l" />
        </div>
      </div>);
    } else if (this.props.showBottle) {
      return (<div className="display-content-loading-container">
        {content}
        <div className="display-content-loading-overlay">
          <NoResults />
        </div>
      </div>);
    } else {
      return content;
    }
  }
}

const mapStateToProps = state => ({
  currentPage: state.currentPage,
  totalPages: state.itemsInfo.totalPages,
  displayType: state.contentDisplayType,
  showBottle: state.showBottle,
  loading: state.loading,
  checkedItems: state.checkedItems,
  items: state.itemsInfo.items,
  lastSelectedIndex: state.lastSelectedIndex,
});

const mapDispatchToProps = dispatch => ({
  itemSelectedChanged: itemSelected => dispatch(itemSelectedChanged(itemSelected)),
  itemsCheckedChanged: checkedItems => dispatch(itemsCheckedChanged(checkedItems)),
  lastSelectedIndexChanged: lastSelectedIndex => dispatch(lastSelectedIndexChanged(lastSelectedIndex)),
  currentPageChanged: currentPage => dispatch(currentPageChanged(currentPage)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentDisplay);
