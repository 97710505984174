import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IconButton from '@hig/icon-button';
import * as Icons from '@hig/icons';
import { pastFutureHistoryChanged, homeReset } from '../redux/actions';
import { T } from '../controller/locale';
import './HistoryNavigation.scss';

const highlightStyles = (styles, props, themeData) => {
  return {
    ...styles,
    iconButton: {
      ...styles.iconButton,
      ...(props.hasFocus ? { boxShadow: 'none' } : {}),
      '&:focus': {
        boxShadow: `0 0 0 ${themeData['iconButton.focus.haloWidth']} ${themeData['iconButton.focus.haloColor']}`,
      },
      '&:hover': {
        boxShadow: 'none',
      },
    },
  };
};

class HistoryNavigation extends React.Component {
  constructor(props) {
    super(props);
    this._handlePastHistory = this._handlePastHistory.bind(this);
    this._handleFutureHistory = this._handleFutureHistory.bind(this);
    this._handleHomeReset = this._handleHomeReset.bind(this);
  }

  // Remove the last element from the past
  // Set the present to the element we removed in the previous step
  // Insert the old present state at the beginning of the future
  _handlePastHistory() {
    let { past, present, future } = this.props.history;
    const previous = past[past.length - 1];
    const newPast = past.slice(0, past.length - 1);
    past = newPast;
    future = [present, ...future];
    present = previous;
    this.props.pastFutureHistoryChanged({ past, present, future });
  }

  // Remove the first element from the future
  // Set the present to the element we removed in the previous step
  // Insert the old present state at the end of the past
  _handleFutureHistory() {
    let { past, present, future } = this.props.history;
    const next = future[0];
    const newFuture = future.slice(1);
    past = [...past, present];
    present = next;
    future = newFuture;
    this.props.pastFutureHistoryChanged({ past, present, future });
  }

  _handleHomeReset() {
    this.props.homeReset();
  }

  render() {
    return (
      <div className="history-navigation">
        <div className="history-navigation-back" id="btn-HISTORY-NAVIGATION-BACK">
          <IconButton
            icon={<Icons.CaretLeft24 />}
            title={T.translate('HN_BACK')}
            disabled={this.props.history.past.length === 0}
            onClick={this._handlePastHistory}
            stylesheet={highlightStyles}
          />
        </div>
        <div className="history-navigation-fwd" id="btn-HISTORY-NAVIGATION-FORWARD">
          <IconButton
            icon={<Icons.CaretRight24 />}
            title={T.translate('HN_FORWARD')}
            disabled={this.props.history.future.length === 0}
            onClick={this._handleFutureHistory}
            stylesheet={highlightStyles}
          />
        </div>
        <div className="history-navigation-home" id="btn-HISTORY-NAVIGATION-HOME">
          <IconButton
            icon={<Icons.Home24 />}
            title={T.translate('HN_HOME')}
            disabled={this.props.history.past.length === 0}
            onClick={this._handleHomeReset}
            stylesheet={highlightStyles}
          />
        </div>
      </div>
    );
  }
}

HistoryNavigation.propTypes = {
  pastFutureHistoryChanged: PropTypes.func,
  history: PropTypes.object,
  homeReset: PropTypes.func,
};

const mapStateToProps = state => ({ history: state.history });

const mapDispatchToProps = dispatch => ({
  pastFutureHistoryChanged: history => dispatch(pastFutureHistoryChanged(history)),
  homeReset: () => dispatch(homeReset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoryNavigation);

