import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { logToJounral } from '../controller/logError';
import FileSaver from 'file-saver';
import ProgressDialog from './ProgressDialog';
import { openProgressDialog } from '../redux/actions';
import { connect } from 'react-redux';
import throttledQueue from 'throttled-queue';

class Downloader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      percentComplete: 0,
      failedItems: [],
    };
    this._handleClearDownloaded = this._handleClearDownloaded.bind(this);
    this._handleFamilyLoadOnFailure = this._handleFamilyLoadOnFailure.bind(this);
    this.cancelTokens = [];
    this.cancelled = false;
    this.numPendingSaves = 0;
  }

  async componentDidMount() {
    const itemValues = Object.values(this.props.itemsToLoad);
    const allFiles = itemValues.reduce(
      (arrB, item) => arrB.concat(item.hostItem.files.reduce((arrS, file) => [...arrS, file], [])), []);
    const totalSize = allFiles.reduce((sz1, file) => sz1 + file.fileSize, 0);
    const numTodo = allFiles.length;
    let numDone = 0;
    const startTime = Date.now();
    const downloadParams = {};
    const downloadTitles = [];

    const collectDownloadAnalytics = () => {
      downloadParams['FCS.Family.Download.Count'] = numTodo;
      downloadParams['FCS.Family.Download.Success'] = !(this.state.failedItems.length > 0);
      downloadParams['FCS.Family.Download.Time'] = Math.round(Date.now() - startTime).toString();
      downloadParams['FCS.Family.Download.Description'] = downloadTitles.toString();
      downloadParams['FCS.Family.Download.Failure.Count'] = this.state.failedItems.length;
      if (this.state.failedItems.length > 0) {
        downloadParams['FCS.Family.Download.Failure.Description'] = this.state.failedItems.toString();
      }
      if (window.HostApp && window.HostApp.collectADPDataEx) {
        window.HostApp.collectADPDataEx(JSON.stringify(downloadParams));
      }
    };
    const failedItems = [];
    // Wait for the downloaded files to get saved to disk. Close App w/ last file.
    document.addEventListener('download_complete', () => {
      --this.numPendingSaves;
      if (this.cancelled) {
        return;
      }
      ++numDone;
      if (numDone === numTodo && failedItems.length === 0) {
        this.props.openProgressDialog(false);
      }
    });

    //
    const onFailureOrCancel = title => () => {
      if (this.cancelled) {
        return;
      }
      ++numDone;
      failedItems.push(title);
    };

    const progress = allFiles.map(() => 0);
    //
    const onProgress = fileId => progressEvent => {
      // It is not cool to call setState() on and unmounted component:
      if (this.cancelled) {
        return;
      }
      progress[fileId] = progressEvent.loaded;
      const totalDone = progress.reduce((tot, xx) => tot + xx, 0);
      const percentComplete = Math.round(
        (totalDone * 100) / totalSize // progressEvent.total
      );
      this.setState({ percentComplete });
    };

    const throttle = throttledQueue(1, 100);
    const CancelToken = axios.CancelToken;
    this.cancelTokens = [];
    // For each file - download then save
    for (let ii = 0; ii < allFiles.length; ++ii) {
      const { url, title } = allFiles[ii];
      downloadTitles.push(title);

      const source = CancelToken.source();
      this.cancelTokens.push(source);
      //
      await axios.get(url, {
        responseType: 'blob',
        timeout: 720000, // 12 minutes in milliseconds, default is `0` (no timeout)
        onDownloadProgress: onProgress(ii),
        cancelToken: source.token,
      }).then(resp => {
        // Suckk! We throttle out of fear of CEF missing events, if we fire them too fast!
        throttle(() => {
          if (this.cancelled) {
            return;
          }
          ++this.numPendingSaves;
          FileSaver.saveAs(resp.data, title);
        });
      }).catch(onFailureOrCancel(title));
    }
    if (failedItems.length > 0) {
      this.setState({ failedItems });
    }
    collectDownloadAnalytics();
  }

  // Need this to clear the downloaded files if download was cancelled
  _handleClearDownloaded() {
    this.cancelled = true;
    for (const cancelToken of this.cancelTokens) {
      cancelToken.cancel();
    }
    this.cancelTokens = [];
    this.props.openProgressDialog(false);
  }

  componentWillUnmount() {
    if (this.cancelled) {
      if (window.HostApp && window.HostApp.onClearDownloaded) {
        const cleanUp = patience => {
          if (this.numPendingSaves > 0 && patience > 0) {
            setTimeout(cleanUp, 500, patience - 1);
            return;
          }
          if (this.numPendingSaves > 0) {
            logToJounral(`Ran out of patience with ${this.numPendingSaves} pending saves`);
          }
          // logToJounral(`Clearing dload folder at patience = ${patience}`);
          window.HostApp.onClearDownloaded();
        };
        cleanUp(11);
      }
    }
    else {
      this.props.onHandleOnLoadMulti();
    }
  }

  // We need this callback because we can't start loading the successfully downloaded families until the user
  // closes the Progress Dialog after viewing the list of files that failed
  _handleFamilyLoadOnFailure() {
    this.props.onHandleOnLoadMulti();
  }

  render() {
    return (
      <div>
        <ProgressDialog
          progressValue={this.state.percentComplete}
          failedItems={this.state.failedItems}
          onClearDownloadedFiles={this._handleClearDownloaded}
          onProceedWithFamilyLoad={this._handleFamilyLoadOnFailure} />
      </div>
    );
  }
}

Downloader.propTypes = {
  onHandleOnLoadMulti: PropTypes.func,
  itemsToLoad: PropTypes.object,
  shouldShowProgress: PropTypes.bool,
  openProgressDialog: PropTypes.func,
};

const mapStateToProps = state => ({
  shouldShowProgress: state.shouldShowProgress,
});

const mapDispatchToProps = dispatch => ({
  openProgressDialog: shouldShowProgress => dispatch(openProgressDialog(shouldShowProgress)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Downloader);
