const transl = {
  '\\': '/',
  '#': '$',
};

const makeSafeId = (str) => {
  return str.replace(/[\\# '"]/g, (ch) => transl[ch] || '_');
};

export default makeSafeId;
